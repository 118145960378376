<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside">
				<UserNavA></UserNavA>
			</div>
			<div class="admin-col whole_000">
				<div class="api_box">
					<div class="api_hd">{{$t('meun13_4')}}</div>
					<div class="api_flex whole_fff">
						<div class="api_flex_top">
							<div class="api_top_t">{{$t('apiTxt2')}}</div>
							<div class="api_top_bd">
								<div class="flex"><span>1.</span>
									<div class="line-2">{{$t('apiTxt3')}}</div>
								</div>
								<div class="flex"><span>2.</span>
									<div class="line-2">{{$t('apiTxt4')}}</div>
								</div>
								<div class="flex"><span>3.</span>
									<div class="line-2">{{$t('apiTxt5')}}</div>
								</div>
							</div>
						</div>
						<form class="api_flex_in">
							<div class="api_grou">
								<div class="api_gr_tt">{{$t('apiTxt6')}}</div>
								<el-input v-model="remark"></el-input>
							</div>
							<div class="api_grou">
								<div class="api_gr_tt">{{$t('apiTxt7')}}</div>
								<el-input v-model="api"></el-input>
							</div>
							<el-button  type="primary" class="btn btn_est" @click="create">{{$t('apibtn')}}</el-button>
						</form>
						<div class="api_flex_table">
							<div class="api_table_bd exte-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="remark" :label="$t('apith1')" width="180">
									</el-table-column>
									<el-table-column prop="createTime" :label="$t('apith2')" width="180">
                    <template slot-scope="scope">
                      <span>{{ scope.row.createTime | datefmt('YYYY-MM-DD HH:mm:ss') }}</span>
                    </template>
									</el-table-column>
									<el-table-column prop="apiKey" label="API Key">
									</el-table-column>
									<el-table-column :label="$t('apith3')">
										<template>
											******
										</template>
									</el-table-column>
									<el-table-column prop="bindIp" :label="$t('apith4')">
									</el-table-column>
									<el-table-column prop="expireTime" :label="$t('apith5')">
                    <template slot-scope="scope">
                      <span>{{ scope.row.expireTime | datefmt('YYYY-MM-DD HH:mm:ss') }}</span>
                    </template>
									</el-table-column>
									<el-table-column prop="operation" :label="$t('th5')">
										<template slot-scope="scope">
											<el-button size="mini" @click="handleEdit(scope.row)">{{$t('edit')}}
											</el-button>
											<el-button size="mini" type="danger" @click="handleDelete(scope.row)">
												{{$t('delete')}}
											</el-button>
										</template>
									</el-table-column>
									<template slot="empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-dialog :visible.sync="dialogVisible" width="380px" custom-class="custom-dialog custom-dialog-tip">
				<div slot="title" class="dialog-title"><b>{{$t('edit')}}</b></div>
				<div class="dialog-content">
					<div class="regi_group api-item">
						<div class="regi_gr_t">{{$t('chTxt11')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<el-input v-model="code" placeholder=""></el-input>
							<el-button :disabled="sendMsgDisabled"  class="btn btn_yzm" @click="sendMobile(1)"
								type="primary">
								<span v-if="sendMsgDisabled">{{time+$t('apisecond')}}</span>
								<span v-if="!sendMsgDisabled">{{$t('clickget')}}</span>
							</el-button>
						</div>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-row :gutter="14">
						<el-col :span="12">
							<el-button @click="dialogVisible = false">{{$t('cancel')}}
							</el-button>
						</el-col>
						<el-col :span="12">
							<el-button type="primary" @click="make">{{$t('confirm2')}}
							</el-button>
						</el-col>
					</el-row>
				</div>
			</el-dialog>
			<el-dialog :visible.sync="dialogVisible2" width="380px" custom-class="custom-dialog custom-dialog-tip">
				<div slot="title" class="dialog-title"><b>{{$t('apiaccess')}}</b></div>
				<div class="dialog-content">
					<div class="dialog-text">
						{{$t('apitips')}}
						<p class="screat">
							<span>{{screat}}</span>
							<span v-clipboard:copy="screat" v-clipboard:success="onCopy" v-clipboard:error="onError"
								class="blue">{{$t('copy')}}</span>
						</p>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-row :gutter="14">
						<el-col :span="12">
							<el-button @click="dialogVisible2 = false">{{$t('cancel')}}
							</el-button>
						</el-col>
						<el-col :span="12">
							<el-button type="primary" @click="dialogVisible2 = false">{{$t('confirm2')}}
							</el-button>
						</el-col>
					</el-row>
				</div>
			</el-dialog>
			<el-dialog :visible.sync="dialogVisible3" width="380px" custom-class="custom-dialog custom-dialog-tip">
				<div slot="title" class="dialog-title"><b>{{$t('delete')}}</b></div>
				<div class="dialog-content">
					<div class="dialog-text">
						{{$t('adtip')}}
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-row :gutter="14">
						<el-col :span="12">
							<el-button @click="dialogVisible3 = false">{{$t('cancel')}}
							</el-button>
						</el-col>
						<el-col :span="12">
							<el-button type="primary" @click="onDel">{{$t('confirm2')}}
							</el-button>
						</el-col>
					</el-row>
				</div>
			</el-dialog>
			<el-dialog :visible.sync="dialogVisible4" width="380px" custom-class="custom-dialog custom-dialog-tip">
				<div slot="title" class="dialog-title"><b>{{$t('edit')}}</b></div>
				<div class="dialog-content">
					<div class="regi_group api-item">
						<div class="regi_gr_t">{{$t('chTxt11')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<el-input v-model="code2" placeholder=""></el-input>
							<el-button :disabled="sendMsgDisabled1" class="btn btn_yzm" @click="sendMobile(2)"
								type="primary">
								<span v-if="sendMsgDisabled1">{{time1+$t('apisecond')}}</span>
								<span v-if="!sendMsgDisabled1">{{$t('clickget')}}</span>
							</el-button>
						</div>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-row :gutter="14">
						<el-col :span="12">
							<el-button @click="dialogVisible4 = false">{{$t('cancel')}}
							</el-button>
						</el-col>
						<el-col :span="12">
							<el-button type="primary" @click="okUpdate">{{$t('confirm2')}}
							</el-button>
						</el-col>
					</el-row>
				</div>
			</el-dialog>
			<el-dialog :visible.sync="dialogVisible5" width="380px" custom-class="custom-dialog custom-dialog-tip">
				<div slot="title" class="dialog-title"><b>{{$t('edit')}}</b></div>
				<div class="dialog-content">
					<div class="regi_group api-item" style="margin-bottom: 20px;">
						<div class="regi_gr_t">{{$t('apiTxt6')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<el-input v-model="newRemark" placeholder=""></el-input>
						</div>
					</div>
					<div class="regi_group api-item">
						<div class="regi_gr_t">{{$t('apith4')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<el-input v-model="newApi" placeholder=""></el-input>
						</div>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-row :gutter="14">
						<el-col :span="12">
							<el-button @click="dialogVisible5 = false">{{$t('cancel')}}
							</el-button>
						</el-col>
						<el-col :span="12">
							<el-button type="primary" @click="update">{{$t('confirm2')}}
							</el-button>
						</el-col>
					</el-row>
				</div>
			</el-dialog>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		openKey,
		apiCode,
		apiSave,
		apiUpdate,
		apiDel
	} from '@/api/api/user'
	import UserNavA from '@/components/UserNavA.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			UserNavA,
			Foot
		},
		data() {
			return {
				remark: '',
				api: '',
				tableData: [],
				dialogVisible: false,
				dialogVisible2: false,
				dialogVisible3: false,
				dialogVisible4: false,
				dialogVisible5: false,
				code: '',
				code2: '',
				time: 60,
				time1: 60,
				sendMsgDisabled: false,
				sendMsgDisabled1: false,
				screat: '',
				row: {},
				newRemark: '',
				newApi: '',
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				openKey().then(res => {
					this.tableData = res.data;
				})
			},
			create() {
				const IP_REG = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
				if (this.remark == "") {
					this.$message.error(this.$t('apierr'));
					return;
				} else if (this.api != "" && !IP_REG.test(this.api)) {
					this.$message.error(this.$t('apierr3'));
					return;
				} else {
					this.dialogVisible = true;
				}
			},
			sendMobile(index) {
				let me = this;
				apiCode().then(res => {
					if (res.code == 0) {
						if (index == 1) {
							this.sendMsgDisabled = true;
							let interval = window.setInterval(function() {
								if (me.time-- <= 0) {
									me.time = 60;
									me.sendMsgDisabled = false;
									window.clearInterval(interval);
								}
							}, 1000);
						} else {
							this.sendMsgDisabled1 = true;
							let interval = window.setInterval(function() {
								if (me.time1-- <= 0) {
									me.time1 = 60;
									me.sendMsgDisabled1 = false;
									window.clearInterval(interval);
								}
							}, 1000);
						}
					} else {
						this.$message.error(res.message);
					}
				})
			},
			make() {
				apiSave({
					remark: this.remark,
					bindIp: this.api,
					code: this.code
				}).then(res => {
					if (res.code==0) {
						this.$message.success(res.message);
						this.remark = "";
						this.bindIp = "";
						this.code = "";
						this.getList();
						this.dialogVisible = false;
						this.screat = res.data;
						this.dialogVisible2 = true;
					} else {
						this.$message.error(res.message);
					}
				})
			},
			onCopy() {
				this.$message.success('success');
			},
			onError() {
				this.$message.error('fail');
			},
			handleEdit(row) {
        this.row = row
				this.dialogVisible4 = true
			},
			handleDelete(row) {
				this.row = row
				this.dialogVisible3 = true
			},
			onDel() {
				apiDel({
					id: this.row.id
				}).then(res => {
					if (!res.code) {
						this.$message.success(res.message);
						this.dialogVisible3 = false;
						this.getList();
					} else {
						this.$message.error(res.message);
					}
				})
			},
			okUpdate() {
				if (this.code2 == '') {
					this.$message.error(this.$t('apierr4'));
					return;
				} else {
					this.dialogVisible4 = false;
					this.dialogVisible5 = true;
				}
			},
			update() {
				const IP_REG = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
				if (this.newRemark == "") {
					this.$message.error(this.$t('apierr'));
					return;
				}
				// if (this.newApi == "") {
				// 	this.$message.error(this.$t('apierr2'));
				// 	return;
				// }
				if (this.newApi != "" && !IP_REG.test(this.newApi)) {
					this.$message.error(this.$t('apierr3'));
					return;
				}
				apiUpdate({
          id: this.row.id,
					remark: this.newRemark,
					bindIp: this.newApi,
					code: this.code2
				}).then(res => {
					if (res.code==0) {
						this.getList();
						this.$message.success(res.message);
						this.dialogVisible5 = false;
					} else {
						this.$message.error(res.message);
					}
				})
			},
		}
	}
</script>
